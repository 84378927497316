import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@components/shadcn/ui/dropdown-menu'
import Text from '@components/typography/Text'
import {
  ChangeLanguageEnIcon,
  ChangeLanguageGeIcon,
  ChangeLanguageRuIcon,
  ChangeLanguageUaIcon,
  GlobeIcon,
} from '@public/icons/sidebar'
import TickIcon from '@public/icons/tick.svg'
import { classnames } from '@tools/common'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'

const ChangeLanguagePopUp = () => {
  const { t } = useTranslation()
  const router = useRouter()

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <div
          className={classnames(
            'w-full h-[80px] flex justify-center cursor-pointer items-center hover:bg-white/10 hover:shadow-2xl aspect-square',
            {
              'bg-white/20 shadow-2xl': router.asPath === '/login',
            },
          )}
        >
          <span>
            {router.locale === 'uk' && (
              <ChangeLanguageUaIcon className="h-5 w-5" />
            )}
            {router.locale === 'ru' && (
              <ChangeLanguageRuIcon className="h-5 w-5" />
            )}
            {router.locale === 'en' && (
              <ChangeLanguageEnIcon className="h-5 w-5" />
            )}
            {router.locale === 'ge' && (
              <ChangeLanguageGeIcon className="h-5 w-5" />
            )}
          </span>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        side="right"
        align="end"
        sideOffset={0}
        className="p-0"
      >
        <ul
          tabIndex={0}
          className="pb-2 bg-white text-primary rounded-tr-md rounded-br-md w-60"
        >
          <Text
            as="p"
            size="caption-default"
            className="px-4 py-3 border-b border-main-300"
          >
            {t('ui.language.title').toUpperCase()}
          </Text>

          {[
            {
              locale: 'en',
              label: t('ui.language.en'),
            },
            { locale: 'ru', label: t('ui.language.ru') },
            { locale: 'uk', label: t('ui.language.uk') },
            { locale: 'ge', label: t('ui.language.ge') },
          ].map(({ locale, label }) => (
            <li key={locale} className="mt-1">
              <Link
                href={router.asPath}
                locale={locale}
                className={classnames(
                  'flex justify-between px-3 py-2 hover:bg-main-100 hover:text-primary focus:text-primary focus:no-underline hover:no-underline',
                  {
                    'bg-background-200': router.locale === locale,
                  },
                )}
              >
                <div className="flex gap-2 items-center">
                  <GlobeIcon className="h-5 w-5" />
                  <Text as="p" size="caption-default">
                    {label}
                  </Text>
                </div>
                {locale === router.locale && <TickIcon className="h-5 w-5" />}
              </Link>
            </li>
          ))}
        </ul>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default ChangeLanguagePopUp
