export { default as DepotIcon } from './depot.svg'
export { default as FinancesIcon } from './finances.svg'
export { default as GlobeIcon } from './globe.svg'
export { default as ChangeLanguageEnIcon } from './lang-en.svg'
export { default as ChangeLanguageGeIcon } from './lang-ge.svg'
export { default as ChangeLanguageRuIcon } from './lang-ru.svg'
export { default as ChangeLanguageUaIcon } from './lang-ua.svg'
export { default as LogOutIcon } from './logout.svg'
export { default as OrderIcon } from './orders.svg'
export { default as ProfileIcon } from './profile.svg'
export { default as RouteIcon } from './routes.svg'
export { default as UsersIcon } from './users.svg'
export { default as WaresToDepotIcon } from './wares.svg'
