import { useAuth, useCompanies } from '@api'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@components/shadcn/ui/tooltip'
import Text from '@components/typography/Text'
import {
  DepotIcon,
  FinancesIcon,
  OrderIcon,
  RouteIcon,
  UsersIcon,
  WaresToDepotIcon,
} from '@public/icons/sidebar'
import CloseIcon from '@public/icons/sidebar/close.svg'
import { TooltipArrow, TooltipProvider } from '@radix-ui/react-tooltip'
import classnames from 'classnames'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { forwardRef, useEffect, useState } from 'react'
import ChangeLanguagePopUp from './ChangeLanguagePopUp'
import ProfilePopUp from './ProfilePopUp'
import LogoIcon from '/public/icons/logo.svg'

export interface SideBarItemsProps {
  Icon: React.ReactNode
  href: string
  tipText: string
}

const Sidebar = forwardRef<
  HTMLElement,
  { className?: string; closeSidebar: () => void }
>(({ className, closeSidebar }, ref) => {
  const { t } = useTranslation()
  const router = useRouter()
  const [companyId, setCompanyId] = useState<string | null>(null)
  const authQuery = useAuth()
  const companyQuery = useCompanies()
  const company = companyQuery.data?.companiesMap.get(companyId ?? '')

  useEffect(() => {
    setCompanyId(localStorage.getItem('companyId'))
  }, [])

  const getSidebarItems = () => {
    const defaultIconProps = {
      className: 'w-6 h-6',
    }

    const items =
      authQuery.isSuccess && companyId && router.asPath !== '/'
        ? [
            {
              Icon: <DepotIcon {...defaultIconProps} />,
              href: `/companies/${companyId}/depots`,
              tipText: t('pages.home.table.depots'),
            },
            {
              Icon: <UsersIcon {...defaultIconProps} />,
              href: `/companies/${companyId}/users`,
              tipText: t('pages.home.table.users'),
            },
            {
              Icon: <OrderIcon {...defaultIconProps} />,
              href: `/companies/${companyId}/orders`,
              tipText: t('pages.home.table.orders'),
            },
            {
              Icon: <RouteIcon {...defaultIconProps} />,
              href: `/companies/${companyId}/routes`,
              tipText: t('pages.home.table.routes'),
            },
            {
              ...(company && !company.simplifiedOrders
                ? {
                    Icon: (
                      <WaresToDepotIcon
                        {...{ ...defaultIconProps, size: 30 }}
                      />
                    ),
                    href: `/companies/${companyId}/waresToDepot`,
                    tipText: t('pages.companies.users.form.wares_to_depot'),
                  }
                : {}),
            },
            {
              ...(company && !company.simplifiedOrders
                ? {
                    Icon: (
                      <FinancesIcon {...{ ...defaultIconProps, size: 30 }} />
                    ),
                    href: `/companies/${companyId}/finances`,
                    tipText: t('pages.companies.users.form.finances'),
                  }
                : {}),
            },
          ]
        : []

    return items.filter(
      item => item.Icon && item.href && item.tipText,
    ) as SideBarItemsProps[]
  }

  return (
    <aside
      ref={ref}
      className={classnames(
        'bg-primary h-dvh flex flex-col items-center text-neutral-content',
        className,
      )}
    >
      <div className="px-2.5 w-full md:hidden">
        <button
          onClick={closeSidebar}
          className="w-full flex justify-end  mt-2 px-1  rounded-lg border-main-500"
        >
          <CloseIcon />
        </button>
      </div>

      <TooltipProvider>
        <Link
          href="/"
          className="pb-2.5 px-2.5 pt-0 sm:pt-2.5 flex items-center justify-center aspect-square rounded-full my-2.5 hover:shadow-2xl"
        >
          <LogoIcon height={35} width={40} />
        </Link>

        <ul className="w-full h-full overflow-y-auto overflow-x-clip flex flex-col items-center">
          {getSidebarItems().map(({ Icon, href, tipText }, index) => (
            <Tooltip key={index}>
              <TooltipTrigger asChild>
                <li
                  onClick={() => router.push(href)}
                  className={classnames(
                    'w-full flex justify-center cursor-pointer items-center hover:bg-white/10 hover:shadow-2xl aspect-square',
                    {
                      'bg-white/20 shadow-2xl':
                        router.asPath.split('?')[0] === href,
                    },
                  )}
                >
                  {Icon}
                </li>
              </TooltipTrigger>

              <TooltipContent side="right" sideOffset={0}>
                <TooltipArrow className="fill-primary" />
                <Text
                  as="p"
                  size="caption-default"
                  className="px-3 py-2 uppercase"
                >
                  {tipText}
                </Text>
              </TooltipContent>
            </Tooltip>
          ))}
        </ul>

        <Tooltip>
          <TooltipTrigger asChild>
            <div>
              <ChangeLanguagePopUp />
            </div>
          </TooltipTrigger>

          <TooltipContent side="right" sideOffset={0}>
            <TooltipArrow className="fill-primary" />
            <Text as="p" size="caption-default" className="px-3 py-2 uppercase">
              {t('ui.language.title')}
            </Text>
          </TooltipContent>
        </Tooltip>

        <Tooltip>
          <TooltipTrigger asChild>
            <div>
              <ProfilePopUp
                fullName={authQuery.data?.fullName}
                login={authQuery.data?.login}
              />
            </div>
          </TooltipTrigger>

          <TooltipContent side="right" sideOffset={0}>
            <TooltipArrow className="fill-primary" />
            <Text as="p" size="caption-default" className="px-3 py-2 uppercase">
              {t('ui.logout')}
            </Text>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </aside>
  )
})

export default Sidebar
